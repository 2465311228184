import { makeAutoObservable } from "mobx";
import { LabelsCreator } from "../labels";
import { Quantity, } from "../ordering";
import { ProductCardCreatorPrice } from "../pricing/ProductCardCreatorPrice";
import { PPrice } from "./PPrice";
import { PProductError } from "./PProductError";
export class PProductCardCreator {
    constructor(params) {
        this.domain = params.domain;
        this.productTypes = params.productTypes;
        this.restaurant = params.restaurant;
        this.localizationProvider = params.localizationProvider;
        this.productInstanceCreator = params.productInstanceCreator;
        this.crossSellingCreator = params.crossSellingCreator;
        this.orderItemListTracking = params.orderItemListTracking;
        makeAutoObservable(this);
    }
    get restaurantId() {
        return this.restaurant.id;
    }
    get availability() {
        return this.domain.availability;
    }
    get state() {
        return this.productInstanceCreator.state;
    }
    get price() {
        return new PPrice(this.localizationProvider, ProductCardCreatorPrice.price(this.domain).spectrum).priceValue;
    }
    get orderingHoursMessage() {
        if (this.domain.orderingHoursAvailability.isOutOfOrderingHours) {
            return PProductError.productCartOfOrderingHours(this.domain.orderingHoursAvailability.nextOrderAvailableAt, this.domain.orderingHoursAvailability.canPreorder, this.domain.orderingHoursAvailability
                .isSpecialBeheviourForAsapOnlyOnTheCurrentDayAnyTimeBeforeOrderingHours, this.domain.orderingHoursAvailability
                .isSpecialBeheviourForAsapOnlyOnTheCurrentDayAnyTimeAfterOrderingHours);
        }
        return PProductError.none();
    }
    get priceValue() {
        const price = ProductCardCreatorPrice.price(this.domain).spectrum;
        switch (price.type) {
            case "value":
                return price.value.asNumber;
            case "range":
                return price.min.asNumber;
            case "null":
                return 0;
        }
    }
    get error() {
        return this.productInstanceCreator.error;
    }
    get quantity() {
        return this.domain.quantity.value;
    }
    get canDecrement() {
        return this.domain.quantity.gt(Quantity.One);
    }
    get canIncrement() {
        return true;
    }
    increment() {
        if (this.canIncrement) {
            this.domain.increment();
        }
    }
    decrement() {
        if (this.canDecrement) {
            this.domain.decrement();
        }
    }
    get labelsCreator() {
        return new LabelsCreator({
            scope: this.productInstanceCreator.scope,
            productInstances: [this.productInstanceCreator.instance],
            view: "ProductCard",
        });
    }
    get product() {
        return this.productInstanceCreator.product;
    }
    get productTypeIdValues() {
        return this.domain.productTypeIds
            .rawValues()
            .map((productTypeId) => productTypeId.value);
    }
    get isSubmitVisible() {
        return this.productInstanceCreator.state.canAddToCart;
    }
    get isSubmitDisabled() {
        return !this.domain.hasResult;
    }
    get isCancelVisible() {
        return !this.productInstanceCreator.state.canAddToCart;
    }
    finish() {
        this.orderItemListTracking.collectCreatorData(this.domain);
        this.productInstanceCreator.touch();
        return this.domain.result;
    }
    get isSplitPossible() {
        return this.domain.isSplitPossible;
    }
    split() {
        this.domain.split();
        this.productInstanceCreator.updateState();
    }
    removeFirstHalf() {
        this.domain.removeFirstHalf();
        this.productInstanceCreator.updateState();
    }
    removeSecondHalf() {
        this.domain.removeSecondHalf();
        this.productInstanceCreator.updateState();
    }
    setFirstHalfProductType(productTypeString) {
        const productType = this.productTypes.rawGet(productTypeString);
        this.domain.setFirstHalfProductType(productType);
        this.productInstanceCreator.updateState();
    }
    setSecondHalfProductType(productTypeString) {
        const productType = this.productTypes.rawGet(productTypeString);
        this.domain.setSecondHalfProductType(productType);
        this.productInstanceCreator.updateState();
    }
    get temporarilyUnavailableIngredients() {
        return this.productInstanceCreator.state.temporarilyUnavailableIngredients;
    }
}
