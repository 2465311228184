import { makeAutoObservable } from "mobx";
export class PSinglePartProductInstanceCreator {
    constructor(params) {
        this.type = "SinglePartCreator";
        this.commonConfigCreator = params.commonConfigCreator;
        this.part = params.part;
        makeAutoObservable(this);
    }
    touch() {
        this.commonConfigCreator.touch();
        this.part.touch();
    }
    get product() {
        return {
            name: this.part.productName,
            photo: this.part.productPhoto,
            description: this.part.productDescription,
            baseIngredients: this.part.productBaseIngredients,
            tags: this.part.productTags,
            allergens: this.part.productAllergens.rawValues(),
            freeModifierItemsCount: this.part.productFreeModifierItemsCount,
        };
    }
    get canAddToCart() {
        return this.part.canAddToCart;
    }
    get temporarilyUnavailableIngredients() {
        return this.commonConfigCreator.temporarilyUnavailableIngredients.union(this.part.temporarilyUnavailableIngredients);
    }
}
export class PHalvesProductInstanceCreator {
    constructor(params) {
        this.type = "HalvesCreator";
        this.commonConfigCreator = params.commonConfigCreator;
        this.firstHalf = params.firstHalf;
        this.secondHalf = params.secondHalf;
        makeAutoObservable(this);
    }
    touch() {
        this.commonConfigCreator.touch();
        this.firstHalf.touch();
        this.secondHalf.touch();
    }
    get product() {
        return {
            name: `${this.firstHalf.productName} / ${this.secondHalf.productName}`,
            photo: this.firstHalf.productPhoto,
            description: null,
            baseIngredients: null,
            tags: [],
            allergens: this.firstHalf.productAllergens
                .union(this.secondHalf.productAllergens)
                .rawValues(),
            freeModifierItemsCount: 0,
        };
    }
    get canAddToCart() {
        return this.firstHalf.canAddToCart && this.secondHalf.canAddToCart;
    }
    get temporarilyUnavailableIngredients() {
        return this.commonConfigCreator.temporarilyUnavailableIngredients
            .union(this.firstHalf.temporarilyUnavailableIngredients)
            .union(this.secondHalf.temporarilyUnavailableIngredients);
    }
}
