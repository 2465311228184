import { OnlinePurchaseOrderFulfillmentTime } from "./OnlinePurchaseOrderFulfillmentTime";
import { QrWaiterPurchaseOrderFulfillmentTime } from "./QrWaiterPurchaseOrderFulfillmentTime";
export const PurchaseOrderFulfillmentTime = {
    create(params) {
        if (params.fulfillmentInstructions.type === "Online") {
            return new OnlinePurchaseOrderFulfillmentTime({
                orderingHours: params.orderingHours,
                preorderSettingsProvider: params.preorderSettingsProvider,
                fulfillmentInstructions: params.fulfillmentInstructions,
                enableDelayedDelivery: params.enableDelayedDelivery,
                allowOrderingInClosedRestaurant: params.allowOrderingInClosedRestaurant,
                now: params.now,
            });
        }
        else {
            return new QrWaiterPurchaseOrderFulfillmentTime();
        }
    },
};
