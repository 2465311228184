import { DateRange } from "./DateRange";
import { makeImmutable } from "./makeImmutable";
export class DysjointDateRanges {
    constructor(ranges) {
        this.ranges = ranges;
        makeImmutable(this);
    }
    get begin() {
        if (this.ranges.length === 0) {
            return null;
        }
        return this.ranges.reduce((prev, curr) => prev.min(curr.begin), this.ranges[0].begin);
    }
    get end() {
        if (this.ranges.length === 0) {
            return null;
        }
        return this.ranges.reduce((prev, curr) => prev.max(curr.end), this.ranges[0].end);
    }
    static singleton(range) {
        return new DysjointDateRanges([range]);
    }
    static fromRanges(ranges) {
        if (ranges.length === 0) {
            return DysjointDateRanges.Empty;
        }
        const sortedRanges = ranges.sort((a, b) => a.begin.compare(b.begin));
        const mergedRanges = [sortedRanges[0]];
        for (let i = 1; i < sortedRanges.length; ++i) {
            const current = sortedRanges[i];
            const lastMerged = mergedRanges[mergedRanges.length - 1];
            if (current.begin.isAfter(lastMerged.end)) {
                mergedRanges.push(current);
            }
            else {
                mergedRanges[mergedRanges.length - 1] = lastMerged.extendEnd(current.end);
            }
        }
        return new DysjointDateRanges(mergedRanges);
    }
    includes(date) {
        return this.ranges.some((range) => range.includes(date));
    }
    allBefore(date) {
        if (this.ranges.length > 0) {
            return this.ranges[this.ranges.length - 1].end.isBefore(date);
        }
        return true;
    }
    union(other) {
        return DysjointDateRanges.fromRanges([...this.ranges, ...other.ranges]);
    }
    intersection(other) {
        const result = [];
        const thisLength = this.ranges.length;
        const otherLength = other.ranges.length;
        if (otherLength === 0) {
            return this;
        }
        let i = 0;
        let j = 0;
        while (i < thisLength && j < otherLength) {
            const a = this.ranges[i];
            const b = other.ranges[j];
            const leftBound = a.begin.max(b.begin);
            const rightBound = a.end.min(b.end);
            if (leftBound.isBefore(rightBound)) {
                result.push(DateRange.fromDates({ begin: leftBound, end: rightBound }));
            }
            if (a.end.isBefore(b.end)) {
                i++;
            }
            else {
                j++;
            }
        }
        return new DysjointDateRanges(result);
    }
    get isEmpty() {
        return this.ranges.length === 0;
    }
}
DysjointDateRanges.Empty = new DysjointDateRanges([]);
